.boxgaleria{
	background-color: white;
	@include box-shadow('0 1px 11px -8px rgba(0,0,0, .8)');
	& .titlebox{
		background-color: $colorTerciary;
		padding: 1rem 1rem;
		color: white;
		font-size: 1rem;
		font-weight: 600;
		text-transform: uppercase;
		margin-top: 0;
		text-align: center;
		& i{
			font-size: 1.2rem;
		}
	}
	
	& .mcmvtext{
		font-size: 1.05rem !important;
		font-weight: 500;
		line-height: 1.4em;
		
	}

	& .buttonbox{
		color: white;
		font-size: 1rem;
		font-weight: 600;
		text-transform: initial;
		border-radius: 5px;
		& i{
			font-size: 1.2rem !important;
		}
	}
	& .textbox{
		font-size: 1.3rem;
		font-weight: 500;
	}
	& .valorbox{
		font-size: 1.2rem !important;
		font-weight: 500;
		line-height: 1.5em;
		padding-left: 0;

		& span{
			font-size: 1.8rem;
			font-weight: 800;
		}

		& strong{
			font-size: 1rem;
			font-weight: 400 !important;
		}

		& .hectares_imovel {
			font-size: .9rem !important;
			font-weight: 400 !important;
		}
	}

	& .documentos_imoloc{
		display: block;
		width: 85%;
		padding: 1rem;
		border: solid 1px $gray4;
		border-radius: .3rem;
		margin: auto;
		text-align: center;
		& p{
			width: 100%;
			font-size: 1.2rem;
			font-weight: 600;
			text-align: center;
			padding: .3rem 0;
			background-color: $colorPrimary;
			color: white;
			border-radius: .3rem;
		}

		& a{
			display: inline-block;
			vertical-align: middle;
			width: 50%;
			& p{
				font-size: 1rem;
				font-weight: 500;
				text-align: center;
				border-radius: .3rem;
				padding: .3rem 0;
				transition: all .15s;
				background-color: transparent;
				color: $gray2;
			}

			&:hover{
				& p{
					background-color: $colorTerciary;
					color: white;
				}
			}
		}
	}
}


.form-contato-fix {
	position: fixed;
	width: 30%;
	top: 20px;
	z-index: 10;
	@include box-shadow('0 4px 11px -2px rgba(0,0,0, .8)');
	transition: box-shadow .5s;
}

/* @media all and (min-width: 1023px){

	.form-contato-fix {
		width: calc(23% - 3rem);
	}
} */