.switch2 {
position: relative;
display: inline-block;
width: 60px;
height: 29px;
}

/* Hide default HTML checkbox */
.switch2 input {
opacity: 0;
width: 0;
height: 0;
}

/* The slider */
.slider2 {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
-webkit-transition: .4s;
transition: .4s;
}

.slider2:before {
position: absolute;
content: "";
height: 22px;
width: 22px;
left: 4px;
bottom: 4px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider2 {
background-color: $colorPrimary;
}

input:focus + .slider2 {
box-shadow: 0 0 1px $colorPrimary;
}

input:checked + .slider2:before {
-webkit-transform: translateX(26px);
-ms-transform: translateX(26px);
transform: translateX(26px);
}

/* Rounded slider2s */
.slider2.round {
border-radius: 34px;
}

.slider2.round:before {
border-radius: 50%;
}





.busca-filtros{
	display: block;
	width: 100%;
	padding: 1rem;
	border: 1px solid $gray1;
	@include border-box;

	& h2 {
		text-align: center;
		border-bottom: 1px solid $gray1;
	}
}


.bg-person{
	background-color: $colorSecondary;
}

.itembuscasup{
	height: 3rem !important;
	& .label{
		height: 3rem !important;
		font-size: 1.2rem !important;
		color: $gray2 !important;
		padding-top: 0.5rem !important;
	}
	
}

.arrowadj{
	& .formsimple-selectmultiple::after{
		padding-top: 0.4rem !important;
	}
}

.detailright{
	& .label::after{
		display: none !important;
	}
}
.bg-btnbusca{
	background-color: $colorPrimary;
	color: white;
	text-align: center;
	font-size: 1.3rem;
	padding-top: 1rem;
	font-weight: 500;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	margin: .15rem 0 .15rem 0;
}

.b-greenp{
	background-color: #00dc77;
	border-color: #00dc77;
	&:hover, &:active, &:focus, &:target{
		background-color: darken(#00dc77, 6);
		border-color: darken(#00dc77, 6);
	}
}

// SETA DA PAGINAÇAO

@-webkit-keyframes uparrow {
	0% { -webkit-transform: translateY(0); opacity: 0.4 }
	100% { -webkit-transform: translateY(-0.4em); opacity: 0.9 }
  }
@-webkit-keyframes downarrow {
0% { -webkit-transform: translateY(0); opacity: 0.4 }
100% { -webkit-transform: translateY(0.4em); opacity: 0.9 }
}

.arrow {
	border-color:transparent;
	border-style:solid;
	border-width:0 2em;
	display:block;
	height:0;
	margin-top: 2rem;
	opacity:0.4;
	text-indent:-9999px;
	transform-origin: 50% 50%;
	width:0;
}
.up {
	-webkit-animation: uparrow 0.6s infinite alternate ease-in-out;
	border-bottom:2em solid $colorPrimary;
}
.down {
	-webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
	border-top:2em solid $colorPrimary;
}


@media all and (max-width: 1023px){

	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		@include border-box;
		z-index: 99999;
		& .label::after{
			display: none !important;
		}
		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
		& .itembuscasup .label {
			font-size: 1rem !important;
			color: $gray1 !important;
		}
	}

	.busca-filtrosssss{
		overflow: auto;
		height: 100vh;
		position: fixed;
		top: 0;
		z-index: 10;
		background: #ffffff;
		left: 0;
		z-index: 99999;

		& .busca-filtros {
			padding-top: 4rem;
			padding-bottom: 7rem;
			border: 0 solid $gray1;
		}
		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
	.busca-filtros-fixed{
		overflow: auto;
		height: 100vh;
		position: fixed;
		top: 0;
		z-index: 10;
		background: #ffffff;
		left: 0;
		z-index: 99999;
	}
}
.btncolorp{
	background-color: $colorSecondary !important;
	margin-right: 1rem;
	&:hover, &:focus, &:active{
		background-color: darken($colorSecondary,4) !important;
	}
}
.busca_container-fixed{
	display: block;
	position: fixed;
	z-index: 10;
}

@media all and (min-width: 1024px) and (max-width: 1200px){
	.codadjs{
		width: 10% !important;
	}
	.textoadjs{
		width: 25% !important;
	}
}