.area_video{
    position: relative;
    background-color: $gray1;
    height: calc(100vh - #{$tamanhoheader}rem);
    overflow: hidden;
    width: 100%;
    & .video_principal{
        display: block;
        width: 100%;
        margin-top: -#{$tamanhoheader}rem;
    }
    & .texto_video{
        position: absolute;
        top: 80%;
        left: 0%;
        background-color: rgba(black, .3);
        width: 100%;
        & p{
            text-align: center;
            color: white;
            font-size: 3rem;
            z-index: 9999999999;
            font-weight: 500;
        }
    }
}
.titulo_num{
    text-align: center;
    font-size: 3rem;
    max-width: 35rem;
    color: white;
}
.sessao_numeros{
    position: relative;
    display: block;
    width: 100%;
    @include border-box;
    padding-left: 5rem;
    & .bloco_num{
        width: 20%;
        margin: 0;
        min-height: 480px;
        padding: 8rem 0;
        display: inline-block;
        @include border-box;
        vertical-align: middle;
        & .num_title{
            display: inline-block;
            vertical-align: top !important;
            font-size: 7rem;
            font-weight: 600;
            color: white;
        }
        & .bloco_texto{
            font-size: 1.7rem;
            font-weight: 500;
            color: white;
        }
    }

    & .a1{
        z-index: 2;
        position: relative;
       
        &::before{
            display: block;
            position: absolute;
            width: 100%;
            height: 50%;
            font-size: 25rem;
            font-weight: 600;
            top: 40%;
            color: rgba(black, .15);
            left: 50%;
            z-index: -1;
            content: '1';
            transform: translateX(-50%);
        }
    }
    & .a2{
        z-index: 2;
        position: relative;
       
        &::before{
            display: block;
            position: absolute;
            width: 100%;
            height: 50%;
            font-size: 25rem;
            font-weight: 600;
            top: 65%;
            color: rgba(black, .15);
            left: 50%;
            z-index: -1;
            content: '2';
            transform: translateX(-50%);
        }
    }
    & .a3{
        z-index: 2;
        position: relative;
       
        &::before{
            display: block;
            position: absolute;
            width: 100%;
            height: 50%;
            font-size: 25rem;
            font-weight: 600;
            top: 40%;
            color: rgba(black, .15);
            left: 50%;
            z-index: -1;
            content: '3';
            transform: translateX(-50%);
        }
    }
    & .a4{
        z-index: 2;
        position: relative;
       
        &::before{
            display: block;
            position: absolute;
            width: 100%;
            height: 50%;
            font-size: 25rem;
            font-weight: 600;
            top: 65%;
            color: rgba(black, .15);
            left: 50%;
            z-index: -1;
            content: '4';
            transform: translateX(-50%);
        }
    }
    & .a5{
        z-index: 2;
        position: relative;
       
        &::before{
            display: block;
            position: absolute;
            width: 100%;
            height: 50%;
            font-size: 25rem;
            font-weight: 600;
            top: 40%;
            color: rgba(black, .15);
            left: 50%;
            z-index: -1;
            content: '5';
            transform: translateX(-50%);
        }
    }

}

.text_apt{
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    line-height: 2rem;
    color: $gray1;
    & p{
        font-size: 1.5rem;
        text-align: center;
        color: $gray2;
    }
}

.imgs{
    display: block;
    width: 100%;
    & .bloco_imgs{
        width: 50%;
        display: inline-block;
        margin-right: 1rem;
        & img{
            width: 99.8%;
            vertical-align: middle;
            
        }
    }
    & .bloco_imgs2{
        width: 46.3%;
        display: inline-block;
        position: relative;
        & img{
            width: 100%;
            vertical-align: middle;
            
        }
        & .filtro_img{
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(black, .5);
            height: 100%;
            width: 100%;
        }
        & .bloco_texto2{
            display: inline-block;
            vertical-align: bottom;
            & p{
                position: absolute;
                bottom: 0;
                left: 5%;
                font-size: 4rem;
                color: white;
            }
        }
    }
}
.bloco_video2{
    width: 50%;
    display: inline-block;
    @include border-box;
    padding: .5rem 0;
    & .video2{
        height: 50%;
        width: 50%;
        margin-left: 0;
        & video{
            height: 40.28vh;
        }
    }
}
.imgs2{
    display: inline-block;
    width: 50%;
    @include border-box;
    padding: .5rem 0;
    & .bloco_imgs2{
        width: 100%;
        display: inline-block;
        position: relative;
        & img{
            width: 100%;
            vertical-align: middle;
            
        }
        & .filtro_img{
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(black, .2);
            height: 100%;
            width: 100%;
        }
        & .bloco_texto2{
            display: inline-block;
            vertical-align: bottom;
            & p{
                position: absolute;
                bottom: 0;
                left: 5%;
                font-size: 4rem;
                color: white;
            }
        }
    }
}
.imgs3{
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-top: 0;
    @include border-box;
    padding: 1rem 0;
    & .bloco_imgs2{
        width: 100%;
        height: auto;
        display: inline-block;
        position: relative;
        & img{
            height: 33.55vw;
            width: 100%;
            vertical-align: middle;
            
        }
        & .filtro_img{
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(black, .5);
            height: 33.55vw;
            width: 100%;
        }
        & .bloco_texto2{
            display: inline-block;
            vertical-align: bottom;
            & p{
                position: absolute;
                bottom: 0;
                left: 5%;
                font-size: 4rem;
                color: white;
            }
        }
    }
}
.bloco_video3{
    width: 45%;
    display: inline-block;
    margin: 1rem 0 0 1rem;
    & .video2{
        height: 50%;
        width: 103%;
        margin-left: 0;
        & video{
            width: 100%;
        }
      
    }
}

.video3{
    width: 100%;
    text-align: center;
    position: relative;
    & video{
        box-shadow: 0 0 25px 0 rgba(black, .5);
    }
    & button{
        position: absolute;
        top: 45%;
        left: 47%;
        color: white;
        font-size: 2rem;
        padding: 1rem;
        border: solid 1px transparent;
        border-radius: 2rem;
        width: 5rem;
        height: 5rem;
        text-align: center;
        background-color: rgba(black, .5);
    }
}
.sessao_formulario{
    & .text_form{
        width: 50%;
        display: inline-block;
        margin-right: 4rem;
        vertical-align: top;
        & .p1{
            color: $gray2;
            font-size: 3rem;
            font-weight: 600;
            margin-bottom: 5rem;
            & strong{
                color: red;
            }
        }
        & .p2{
            font-size: 1.5rem;
            font-weight: 400;
            margin-bottom: 5rem;
            color: $gray2;
        }
        & .p3{
            color: $gray2;
            font-size: 1.8rem;
            font-weight: 600;
        }
    }

    & .form_investir{
        vertical-align: top;
        width: 45%;
        display: inline-block;
        margin-top: 1rem;
    }
}

@media all and (min-width: 1600px) and (max-width: 1900px){
    .area_video{
        position: relative;
        background-color: $gray1;
        height: calc(100vh - #{$tamanhoheader}rem);
        overflow: hidden;
        width: 100%;
        & .video_principal{
            display: block;
            width: 100%;
            margin-top: -#{$tamanhoheader}rem;
            & video{
                height: 100vh;
            }
        }
        & .texto_video{
            position: absolute;
            top: 75%;
            left: 0%;
            background-color: rgba(black, .3);
            width: 100%;
            & p{
                text-align: center;
                color: white;
                font-size: 4rem;
                z-index: 9999999999;
                font-weight: 500;
            }
        }
    }
    .bloco_video2{
        width: 50%;
        display: inline-block;
        margin: 1rem 1rem 1.5rem 0;
        & .video2{
            height: 50%;
            width: 50%;
            margin-left: 0;
            & video{
                height: 43.8vh;
            }
        }
    }
    .imgs3{
        display: inline-block;
        width: 50%;
        vertical-align: top;
        margin-top: -18rem;
        & .bloco_imgs2{
            width: 100%;
            height: 68.5vh;
            display: inline-block;
            position: relative;
            & img{
                height: 105.5vh;
                width: 100%;
                vertical-align: middle;
                
            }
            & .filtro_img{
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(black, .5);
                height: 105.5vh;
                width: 100%;
            }
            & .bloco_texto2{
                display: inline-block;
                vertical-align: bottom;
                & p{
                    position: absolute;
                    bottom: -52%;
                    left: 5%;
                    font-size: 4rem;
                    color: white;
                }
            }
        }
    }
}
@media all and (min-width: 1325px) and (max-width: 1599px){
    .area_video{
        position: relative;
        background-color: $gray1;
        height: calc(100vh - #{$tamanhoheader}rem);
        overflow: hidden;
        width: 100%;
        & .video_principal{
            display: block;
            width: 100%;
            margin-top: -#{$tamanhoheader}rem;
            & video{
                height: 144.5vh;
            }
        }
        & .texto_video{
            position: absolute;
            top: 75%;
            left: 0%;
            background-color: rgba(black, .3);
            width: 100%;
            & p{
                text-align: center;
                color: white;
                font-size: 2.5rem;
                z-index: 9999999999;
                font-weight: 500;
            }
        }
    }
    .bloco_video2{
        width: 100%;
        display: block;
        @include border-box;
        padding: .5rem;
        margin: auto -.5rem;
        & .video2{
            width: 100%;
            margin-left: 0;
            & video{
                width: 100%;
                height: 55vw;
            }
        }
    }
    .imgs3{
        display: inline-block;
        vertical-align: top;
        @include border-box;
        padding: 1rem 0;
        margin-top: 0;
        & .bloco_imgs2{
            position: relative;
            width: 100%;
            height: 44.7vw;
            display: inline-block;
            & img{
                height: 44.7vw;
                width: 100%;
                vertical-align: middle;
                
            }
            & .filtro_img{
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(black, .5);
                height: 44.7vw;
                width: 100%;
            }
            & .bloco_texto2{
                display: inline-block;
                vertical-align: bottom;
                & p{
                    position: absolute;
                    bottom: 2%;
                    left: 5%;
                    font-size: 4rem;
                    color: white;
                }
            }
        }
    }
    .video3{
        width: 100%;
        text-align: center;
        position: relative;
        & video{
            box-shadow: 0 0 25px 0 rgba(black, .5);
            width: 75%;
        }
        & button{
            position: absolute;
            top: 45%;
            left: 47%;
            color: white;
            font-size: 2rem;
            padding: 1rem;
            border: solid 1px transparent;
            border-radius: 2rem;
            width: 5rem;
            height: 5rem;
            text-align: center;
            background-color: rgba(black, .5);
        }
    }
}
@media all and (min-width: 1240px) and (max-width: 1324px){
    .area_video{
        position: relative;
        background-color: $gray1;
        height: calc(100vh - #{$tamanhoheader}rem);
        overflow: hidden;
        width: 100%;
        & .video_principal{
            display: block;
            width: 100%;
            margin-top: -#{$tamanhoheader}rem;
            & video{
                height: 149vh;
            }
        }
        & .texto_video{
            position: absolute;
            top: 75%;
            left: 0%;
            background-color: rgba(black, .3);
            width: 100%;
            & p{
                text-align: center;
                color: white;
                font-size: 2rem;
                z-index: 9999999999;
                font-weight: 500;
            }
        }
    }
    .bloco_video2{
        width: 50%;
        display: inline-block;
        margin: 1rem 1rem 1.5rem 0;
        @include border-box;
        & .video2{
            height: 50%;
            width: 50%;
            margin-left: 0;
            & video{
                height: 72.35vw;
            }
        }
    }
    .imgs{
        display: block;
        width: 100%;
        @include border-box;
        & .bloco_imgs{
            width: 50%;
            display: inline-block;
            & img{
                width: 100%;
                vertical-align: middle;
            }
        }
        & .bloco_imgs2{
            width: 48.5%;
            display: inline-block;
            position: relative;
            @include border-box;
            & img{
                width: 95.5%;
                vertical-align: middle;
                
            }
            & .filtro_img{
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(black, .5);
                height: 100%;
                width: 95.5%;
            }
            & .bloco_texto2{
                display: inline-block;
                vertical-align: bottom;
                & p{
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    font-size: 4rem;
                    color: white;
                }
            }
        }
    }
    .imgs3{
        display: inline-block;
        width: 50%;
        vertical-align: top;
        margin-top: 0;
        @include border-box;
        padding: 1rem 0;
        & .bloco_imgs2{
            width: 100%;
            height: auto;
            display: inline-block;
            position: relative;
            & img{
                height: auto;
                width: 100%;
                height: 44.8vw;
                vertical-align: middle;
                
            }
            & .filtro_img{
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(black, .5);
                height: 44.8vw;
                width: 100%;
            }
            & .bloco_texto2{
                display: inline-block;
                vertical-align: bottom;
                & p{
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    font-size: 4rem;
                    color: white;
                }
            }
        }
    }
    .video3{
        width: 100%;
        text-align: center;
        position: relative;
        & video{
            box-shadow: 0 0 25px 0 rgba(black, .5);
            width: 80%;
        }
        & button{
            position: absolute;
            top: 45%;
            left: 47%;
            color: white;
            font-size: 2rem;
            padding: 1rem;
            border: solid 1px transparent;
            border-radius: 2rem;
            width: 5rem;
            height: 5rem;
            text-align: center;
            background-color: rgba(black, .5);
        }
    }
}
@media all and (min-width: 1024px) and (max-width: 1239px){
    .area_video{
        position: relative;
        background-color: $gray1;
        height: calc(138.5vh - #{$tamanhoheader}rem);
        overflow: hidden;
        width: 100%;
        & .video_principal{
            display: block;
            width: 100%;
            margin-top: -#{$tamanhoheader}rem;
            & video{
                height: 138.5vh;
            }
        }
        & .texto_video{
            position: absolute;
            top: 75%;
            left: 0%;
            background-color: rgba(black, .3);
            width: 100%;
            & p{
                text-align: center;
                color: white;
                font-size: 2.5rem;
                z-index: 9999999999;
                font-weight: 500;
            }
        }
    }
    
    $bloco_video2_width: 20;

    .bloco_video2{
        width: 100%;
        display: block;
        margin: 1rem 0;
        position: relative;
        height: 41vw;
        overflow: hidden;
        & .video2{
            width: 120%;
            position: absolute;
            left: -10px;
            top: 0;

            & video{
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }
    .imgs2{
        display: inline-block;
        width: 50%;
    }
    .imgs3{
        display: inline-block;
        width: 50%;
        height: auto;
        vertical-align: top;
        margin: 0;
        margin-top: 1.1rem;
        @include border-box;
        & .bloco_imgs2{
            height: auto;
            display: block;
            width: 100%;
            position: relative;
            @include border-box;
            & img{
                display: block;
                width: 100%;
                height: 44.5vw;
            }
            & .filtro_img{
                position: absolute;
                left: 0;
                top: 0;
                bottom:0;
                height: 44.6vw;
                background-color: rgba(black, .5);
                width: 100%;
            }
            & .bloco_texto2{
                position: absolute;
                width: 100%;
                bottom: 0;
                & p{
                    font-size: 4rem;
                    color: white;
                    width: 100%;
                }
            }
        }
            
    }
    .video3{
        width: 100%;
        text-align: center;
        position: relative;
        & video{
            box-shadow: 0 0 25px 0 rgba(black, .5);
            width: 80%;
        }
        & button{
            position: absolute;
            top: 45%;
            left: 47%;
            color: white;
            font-size: 2rem;
            padding: 1rem;
            border: solid 1px transparent;
            border-radius: 2rem;
            width: 5rem;
            height: 5rem;
            text-align: center;
            background-color: rgba(black, .5);
        }
    }

    .sessao_formulario{
        & .text_form{
            width: 50%;
            display: inline-block;
            margin-right: 0;
            vertical-align: top;
            & .p1{
                color: $gray2;
                font-size: 2.5rem;
                font-weight: 600;
                margin-bottom: 3rem;
                margin-top: 5rem;
                & strong{
                    color: red;
                }
            }
            & .p2{
                font-size: 1.5rem;
                font-weight: 400;
                margin-bottom: 3rem;
                color: $gray2;
            }
            & .p3{
                color: $gray2;
                font-size: 1.7rem;
                font-weight: 600;
            }
        }

        & .form_investir{
            vertical-align: top;
            width: 45%;
            display: inline-block;
            margin-top: 1rem;
        }
    }
}
@media all and (min-width: 376px) and (max-width: 1023px){
    .area_video{
        position: relative;
        background-color: $gray1;
        height: 31.5vh;
        overflow: hidden;
        width: 100%;
        & .video_principal{
            display: block;
            width: 100%;
            margin-top: 0;
            & video{
                height: 32vh;
            }
        }
        & .texto_video{
            position: absolute;
            top: 75%;
            left: 0%;
            background-color: rgba(black, .3);
            width: 100%;
            & p{
                text-align: center;
                color: white;
                font-size: 1rem;
                z-index: 9999999999;
                font-weight: 500;
            }
        }
    }

    .titulo_num{
        text-align: center;
        font-size: 2rem;
        max-width: 15rem;
        color: white;
    }
    .sessao_numeros{
        position: relative;
        display: block;
        width: 100%;
        @include border-box;
        padding-left: 5rem;

        & .bloco_num{
            width: 20%;
            margin: 0;
            min-height: 5rem;
            padding: 8rem 0;
            display: block;
            @include border-box;
            & .num_title{
                display: block;
                font-weight: 600;
                color: white;
            }
            & .bloco_texto{
                font-size: 1.5rem;
                font-weight: 500;
                color: white;
                min-width: 15rem;
            }
        }

        & .a1{
            z-index: 2;
            position: relative;
            &::before{
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                font-size: 25rem;
                font-weight: 600;
                top: 45%;
                color: rgba(black, .15);
                left: 100%;
                z-index: -1;
                content: '1';
                transform: translateX(-50%);
            }
        }
        & .a2{
            z-index: 2;
            position: relative;
        
            &::before{
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                font-size: 25rem;
                font-weight: 600;
                top: 35%;
                color: rgba(black, .15);
                left: 100%;
                z-index: -1;
                content: '2';
                transform: translateX(-50%);
            }
        }
        & .a3{
            z-index: 2;
            position: relative;
        
            &::before{
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                font-size: 25rem;
                font-weight: 600;
                top: 35%;
                color: rgba(black, .15);
                left: 100%;
                z-index: -1;
                content: '3';
                transform: translateX(-50%);
            }
        }
        & .a4{
            z-index: 2;
            position: relative;
        
            &::before{
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                font-size: 25rem;
                font-weight: 600;
                top: 40%;
                color: rgba(black, .15);
                left: 50%;
                z-index: -1;
                content: '4';
                transform: translateX(-50%);
            }
        }
        & .a5{
            z-index: 2;
            position: relative;
        
            &::before{
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                font-size: 25rem;
                font-weight: 600;
                top: 40%;
                color: rgba(black, .15);
                left: 50%;
                z-index: -1;
                content: '5';
                transform: translateX(-50%);
            }
        }
    }

    .text_apt{
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
        line-height: 2rem;
        color: $gray1;
        & p{
            font-size: 1rem;
            text-align: center;
            color: $gray2;
        }
    }

    .imgs{
        display: block;
        width: 100%;
        & .bloco_imgs{
            width: 100%;
            display: block;
            margin: 1rem 0;
            & img{
                width: 100%;
                vertical-align: middle;
                
            }
        }
        & .bloco_imgs2{
            width: 100%;
            display: block;
            position: relative;
            & img{
                width: 100%;
                vertical-align: middle;
                
            }
            & .filtro_img{
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(black, .5);
                height: 100%;
                width: 100%;
            }
            & .bloco_texto2{
                display: block;
                & p{
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    font-size: 2rem;
                    color: white;
                }
            }
        }
    }

    $bloco_video2_width: 20;

    .bloco_video2{
        width: 100%;
        display: block;
        margin: 1rem 0;
        position: relative;
        height: 41vw;
        overflow: hidden;
        & .video2{
            width: 120%;
            position: absolute;
            left: -10px;
            top: 0;

            & video{
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }

    .imgs2{
        display: block;
        width: 100%;
        margin-top: 1rem;
        & .bloco_imgs2{
            width: 100%;
            display: block;
            position: relative;
            & img{
                width: 100%;
                vertical-align: middle;
            }
            & .filtro_img{
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(black, .2);
                height: 100%;
                width: 100%;
            }
            & .bloco_texto2{
                display: block;
                & p{
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    font-size: 4rem;
                    color: white;
                }
            }
        }
    }
    .imgs3{
        display: block;
        width: 100%;
        margin: 1rem 0;
        & .bloco_imgs2{
            width: 100%;
            height: 100%;
            display: block;
            position: relative;
            & img{
                height: 100%;
                width: 100%;
            }
            & .filtro_img{
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(black, .5);
                height: 100%;
                width: 100%;
            }
            & .bloco_texto2{
                display: block;
                & p{
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    font-size: 2rem;
                    color: white;
                }
            }
        }
    }

    .bloco_video3{
        width: 100%;
        display: inline-block;
        margin: 1rem 0;
        & .video2{
            height: 100%;
            width: 100%;
            margin-left: 0;
            & video{
                width: 100%;
            }
          
        }
    }

    .video3{
        width: 100%;
        text-align: center;
        position: relative;
        & video{
            box-shadow: 0 0 25px 0 rgba(black, .5);
            width: 100%;
        }
    }

    .sessao_formulario{
        & .text_form{
            width: 100%;
            display: inline-block;
            & .p1{
                color: $gray2;
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 3rem;
                margin-right: 0;
                & strong{
                    color: red;
                }
            }
            & .p2{
                font-size: 1.2rem;
                font-weight: 400;
                margin-bottom: 3rem;
                color: $gray2;
            }
            & .p3{
                color: $gray2;
                font-size: 1.5rem;
                font-weight: 600;
              margin-bottom: 5rem;
            }
        }

        & .form_investir{
            vertical-align: top;
            width: 100%;
            display: inline-block;
            margin-top: 1rem;
        }
    }
}
@media all and (max-width: 375px){
    .area_video{
        position: relative;
        background-color: $gray1;
        height: 31.5vh;
        overflow: hidden;
        width: 100%;
        & .video_principal{
            display: block;
            width: 100%;
            margin-top: 0;
            & video{
                height: 32vh;
            }
        }
        & .texto_video{
            position: absolute;
            top: 75%;
            left: 0%;
            background-color: rgba(black, .3);
            width: 100%;
            & p{
                text-align: center;
                color: white;
                font-size: 1rem;
                z-index: 9999999999;
                font-weight: 500;
            }
        }
    }

    .titulo_num{
        text-align: center;
        font-size: 2rem;
        max-width: 15rem;
        color: white;
    }
    .sessao_numeros{
        position: relative;
        display: block;
        width: 100%;
        @include border-box;
        padding-left: 5rem;

        & .bloco_num{
            width: 20%;
            margin: 0;
            min-height: 5rem;
            padding: 8rem 0;
            display: block;
            @include border-box;
            & .num_title{
                display: block;
                font-weight: 600;
                color: white;
            }
            & .bloco_texto{
                font-size: 1.5rem;
                font-weight: 500;
                color: white;
                min-width: 15rem;
            }
        }

        & .a1{
            z-index: 2;
            position: relative;
            &::before{
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                font-size: 25rem;
                font-weight: 600;
                top: 45%;
                color: rgba(black, .15);
                left: 100%;
                z-index: -1;
                content: '1';
                transform: translateX(-50%);
            }
        }
        & .a2{
            z-index: 2;
            position: relative;
        
            &::before{
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                font-size: 25rem;
                font-weight: 600;
                top: 35%;
                color: rgba(black, .15);
                left: 100%;
                z-index: -1;
                content: '2';
                transform: translateX(-50%);
            }
        }
        & .a3{
            z-index: 2;
            position: relative;
        
            &::before{
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                font-size: 25rem;
                font-weight: 600;
                top: 35%;
                color: rgba(black, .15);
                left: 100%;
                z-index: -1;
                content: '3';
                transform: translateX(-50%);
            }
        }
        & .a4{
            z-index: 2;
            position: relative;
        
            &::before{
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                font-size: 25rem;
                font-weight: 600;
                top: 40%;
                color: rgba(black, .15);
                left: 50%;
                z-index: -1;
                content: '4';
                transform: translateX(-50%);
            }
        }
        & .a5{
            z-index: 2;
            position: relative;
        
            &::before{
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                font-size: 25rem;
                font-weight: 600;
                top: 40%;
                color: rgba(black, .15);
                left: 50%;
                z-index: -1;
                content: '5';
                transform: translateX(-50%);
            }
        }
    }

    .text_apt{
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
        line-height: 2rem;
        max-width: 25rem;
        color: $gray1;
        & p{
            font-size: 1rem;
            text-align: center;
            color: $gray2;
        }
    }

    .imgs{
        display: block;
        width: 99.5%;
        & .bloco_imgs{
            width: 99.5%;
            display: block;
            margin: 1rem 0;
            & img{
                width: 99.5%;
                vertical-align: middle;
                
            }
        }
        & .bloco_imgs2{
            width: 99.5%;
            display: block;
            position: relative;
            & img{
                width: 99.5%;
                vertical-align: middle;
                
            }
            & .filtro_img{
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(black, .5);
                height: 99.5%;
                width: 99.5%;
            }
            & .bloco_texto2{
                display: block;
                & p{
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    font-size: 2rem;
                    color: white;
                }
            }
        }
    }

    .bloco_video2{
        width: 50%;
        display: block;
        margin: 1rem 0;
        & .video2{
            height: 50%;
            width: 99.5%;
            margin-left: 0;
            & video{
                height: 27.2vh;
            }
        }
    }

    .imgs2{
        display: block;
        width: 99.5%;
        margin-top: 1rem;
        & .bloco_imgs2{
            width: 99.5%;
            display: block;
            position: relative;
            & img{
                width: 99.5%;
                vertical-align: middle;
            }
            & .filtro_img{
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(black, .2);
                height: 99.5%;
                width: 99.5%;
            }
            & .bloco_texto2{
                display: block;
                & p{
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    font-size: 4rem;
                    color: white;
                }
            }
        }
    }
    .imgs3{
        display: block;
        width: 99.5%;
        margin: 1rem 0;
        & .bloco_imgs2{
            width: 99.5%;
            height: 99.5%;
            display: block;
            position: relative;
            & img{
                height: 99.5%;
                width: 99.5%;
            }
            & .filtro_img{
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(black, .5);
                height: 99.5%;
                width: 99.5%;
            }
            & .bloco_texto2{
                display: block;
                & p{
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    font-size: 2rem;
                    color: white;
                }
            }
        }
    }

    .bloco_video3{
        width: 99.5%;
        display: inline-block;
        margin: 1rem 0;
        & .video2{
            height: 99.5%;
            width: 99.5%;
            margin-left: 0;
            & video{
                width: 99.5%;
            }
          
        }
    }

    .video3{
        width: 100%;
        text-align: center;
        position: relative;
        & video{
            box-shadow: 0 0 25px 0 rgba(black, .5);
            width: 100%;
        }
    }

    .sessao_formulario{
        & .text_form{
            width: 100%;
            display: inline-block;
            & .p1{
                color: $gray2;
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 3rem;
                margin-right:  0;
                & strong{
                    color: red;
                }
            }
            & .p2{
                font-size: 1.2rem;
                font-weight: 400;
                margin-bottom: 3rem;
                color: $gray2;
            }
            & .p3{
                color: $gray2;
                font-size: 1.5rem;
                font-weight: 600;
              margin-bottom: 5rem;
            }
        }

        & .form_investir{
            vertical-align: top;
            width: 100%;
            display: inline-block;
            margin-top: 1rem;
        }
    }
}