.imgdoc{
    height: 54vh;
    background-image: url('/jnh/documentos/banner2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: white;
    line-height: 0;
}

.textdoc{
        font-size: 1.8rem;
        color: $gray2;
        font-weight: 500;
        max-width: 25rem;
        line-height: 1.3em;
    & strong{
        font-size: 1.9rem;
        font-weight: 500 !important;
        color: $colorPrimary;
    }
}

.loctypes{
    h2 {
        font-weight: 600;
        & i{
            color: $colorPrimary;
        }
    }

    p{
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.6em;
        color: $gray2;
    }
}

.heighttop{
    height: 54vh;
}

@media all and (max-width: 1023px){
    .heighttop{
        height: 20rem;
    }
}