.miniatura-imovel2 {
	display: inline-block;
	vertical-align: top;

	& .miniatura-imovel-img{

		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 60%;
		background-color: $gray1; 
		position: relative;
		user-select: none;

		& img{
			display: block;
			width: 100%;
			position: absolute;
			left: 0;
			top: -30%;
		}

		& .miniatura-arrow{
			display: block;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			padding: 0;
			@include border-box;

			& button {
				display: block;
				line-height: 3rem;
				width: 3rem;
				border: none;
				padding: 0;
				margin: 0;
				outline: none;
				cursor: pointer;
				font-size: 2rem;
			}
		}

		& .arrow-left{
			left: 0;
		}
		& .arrow-right{
			right: 0;
		}
	}
	
	& .miniatura-imovel-img-video {
		@extend .miniatura-imovel-img;

		padding-top: 70%;
		position: relative;
		
		& img {
			top: -8px;
		}

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			font-size: 4rem;
			color: #ffffff;
		}
	}

	& .miniatura-informacoes {
		text-align: center;
		padding: 0 .5rem;
		@include border-box;

		& .left {
			color: $gray1;
			font-size: 1.2rem;
		}
		& .right {
			color: $gray1;
			font-size: .8rem;
			letter-spacing: -.09rem;
    		font-weight: 300;
		}
	}

	& .miniatura-cidade-estado{
		display: inline-block;
		width: calc(100% - 3rem);
		height: 1.3rem;
		overflow: hidden;
		vertical-align: middle;
		text-transform: uppercase;
		margin-bottom: .5rem;

		font-size: 1.1rem;
		font-weight: 700;
		color: $gray1;
	}
	& .miniatura-acao-tipo{
		font-size: 1rem;
		font-weight: 500;
		color: $gray2;
	}

	& .miniatura-container-id{
		display: inline-block;
		width: 5rem;
		vertical-align: middle;
		text-align: right;
	}

	& .miniatura-imovel-endereco {
		font-style: oblique;
	}

	& .miniatura-imovel-preco {
		padding: .5rem;
		@include border-box;
		background-color: $colorPrimary;
		font-size: 1.2rem;
	}

	& .miniatura-p {

		color: $gray2 !important;
		font-weight: 600;
		font-size: 1.1rem;
	}

	& .miniatura-imovel-detalhe{
		display: block;
		width: 100%;
		padding: 1rem;
		background-color: $gray6;
		border-radius: 0px 0px 7px 7px;
		@include border-box;
		position: relative;

		& .miniatura-btn-favorito {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}

		& .miniatura-imovel-btns-icons{
			display: block;
			width: 100%;
			overflow: hidden;
			min-height: 22px;
			height: 2.4rem;

			& .miniatura-imovel-icone {
				display: inline-block;
				width: 25%;
				vertical-align: middle;
			}

			& .miniatura-informacoes{

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					padding-right: 0;
				}
			}
		}
	}
}
