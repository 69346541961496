.perso-bord{
    border: solid 1px $colorPrimary !important;
}

.perso-bord2{
    border: solid 1px $gray2 !important;
}

.buttoncurriculo{
    background-color: $gray6;
    height: 3rem;
    cursor: pointer;
    position: relative;
    &:hover{
        background-color: darken($gray6,5);
    }
    & .textcurriculo{
        text-align: center;
        font-size: 1.1rem;
        pointer-events: none;
        color: $gray2;
        cursor: pointer;
        font-weight: 500;
        line-height: 3rem;
    }
    input{
        width: 100%; 
        height: inherit; 
        position: absolute; 
        top: 0px; 
        left: 0px; 
        right: 0px; 
        bottom: 0px; 
        opacity: 0; 
        cursor: pointer; 
        height: 0;
        width: 0;
    }
}