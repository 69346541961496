
@media all and (max-width: 1024px){
    .text-center-mobile{
        text-align: center !important;
    }
    .maxmobile{
        max-width: 25rem !important;
    }
    .colormobile{
        background-color: white;
    }
    .bgmobile{
        background-color: $gray5 !important;
    }
    .bgmobile2{
        background-color: white !important;
    }
    .reset-a{
        & .block{
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}