

.reset-a{
	&:hover, &:focus, &:active, &:visited{ 
		color: $gray1;
	}
}

.positionshare{
    position: fixed;
    left: 2%;
    top: 40%;
}

.formsimple-adjus{
	& ::after {
		display: none !important;
	}
	& .label{
		background-color: $gray5 !important;
		&:hover{
			background-color: darken($gray5,5) !important;
		}
	}

	
}

.text-grupo{
    color: $colorPrimary;
    font-weight: 600;
    font-size: 1.2rem;
}

.select-rmv{
    .formsimple-selectmultiple{
        & .label{
            border-radius: 5px !important;
        }
        & .component {
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;
            & .botoes {
                display: none !important;
            }
        }
        &::after {	
            position: absolute;
            top: 0;
            right: 20px !important;
            pointer-events:none;
            bottom: 0;
            display: block;
            z-index: 1;
            text-align: center;
            width: 1rem;
            height: 0rem;
            line-height: #{$formsimple_inputHeight - ($formsimple_inputBorder * 2)}px;
            content: "\f078";
            font-size: 17px !important;
            color: $gray2;
            font-family: 'Objetiva Software Light' !important;
            transform: rotate(0deg);
            transform-origin: center;
            transition: transform 0.15s ease;
        }
    }
}

.miniatura-imovel3 {
	display: inline-block;
	vertical-align: top;
	& .shadow3{
		&:hover{
			@extend .shadow-box;
		}
		& .miniatura-imovel-img3{
		
			display: block;
			width: 100%;
			overflow: hidden;
			padding-top: 55%;
			background-color: $gray1; 
			position: relative;
			user-select: none;
	
			& img{
				display: block;
				width: 100%;
				position: absolute;
				left: 0;
				top: 0;
			}
	
			& .miniatura-arrow3{
				display: block;
				top: 50%;
				transform: translateY(-50%);
				position: absolute;
				padding: 0;
				@include border-box;
	
				& button {
					display: block;
					line-height: 3rem;
					width: 3rem;
					border: none;
					padding: 0;
					margin: 0;
					outline: none;
					cursor: pointer;
					font-size: 2rem;
				}
			}
	
			& .arrow-left{
				left: 0;
			}
			& .arrow-right{
				right: 0;
			}
		}
		
		& .miniatura-imovel-img-video {
			@extend .miniatura-imovel-img3;
	
			padding-top: 70%;
			position: relative;
			
			& img {
				top: -8px;
			}
	
			& .miniatura-video-player {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
	
				font-size: 4rem;
				color: #ffffff;
			}
		}
	
		& .miniatura-informacoes3 {
			text-align: center;
			padding: 0 .5rem;
			@include border-box;
	
			& .left {
				color: $gray1;
				font-size: 1.2rem;
			}
			& .right {
				color: $gray1;
				font-size: .8rem;
				letter-spacing: -.09rem;
				font-weight: 300;
			}
		}
	
		& .miniatura-cidade-estado{
			display: inline-block;
			width: calc(100% - 3rem);
			height: 1.3rem;
			overflow: hidden;
			vertical-align: middle;
			text-transform: uppercase;
			margin-bottom: .5rem;
	
			font-size: 1.1rem;
			font-weight: 700;
			color: $gray1;
		}
		& .miniatura-acao-tipo{
			font-size: 1rem;
			font-weight: 500;
			color: $gray2;
		}
	
		& .miniatura-container-id{
			display: inline-block;
			width: 5rem;
			vertical-align: middle;
			text-align: right;
		}
	
		& .miniatura-imovel-endereco {
			font-style: oblique;
		}
	
		& .miniatura-imovel-preco {
			padding: .5rem;
			@include border-box;
			background-color: $colorPrimary;
			font-size: 1.2rem;
		}
	
		& .miniatura-p {
	
			color: $gray2 !important;
			font-weight: 600;
			font-size: 1.1rem;
		}
	
		& .miniatura-imovel-detalhe3{
			display: block;
            width: 100%;
			padding: 1rem;
			background-color: white;
			@include border-box;
			position: relative;
			
			& .miniatura-btn-favorito {
				position: absolute;
				top: 1rem;
				right: 1rem;
			}
	
			& .miniatura-imovel-btns-icons{
				display: block;
				width: 100%;
				overflow: hidden;
				min-height: 22px;
				height: 2.4rem;
	
				& .miniatura-imovel-icone {
					display: inline-block;
					width: 25%;
					vertical-align: middle;
				}
	
				& .miniatura-informacoes{
	
					&:first-child {
						padding-left: 0;
					}
					&:last-child {
						padding-right: 0;
					}
				}
			}
		}
	}
	
}